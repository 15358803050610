* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.imageLogo {
  width: 70%;
  height: 70%;
}

.biggerText {
  font-size: 12vh;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.biggerTextWhite {
  font-size: 13vh;  
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.mediumTextWhite {
  font-size: 10vh;  
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


.center {
  position: absolute;
  top: 45%;
  //left: 5%;
  transform: translate(0, -50%);
  padding: 10px;
  }

@media (max-width:480px) {

  .biggerText {
    font-size: 5vh;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  
  .biggerTextWhite {
    font-size: 4vh;
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .mediumTextWhite {
    font-size: 3vh;  
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  
}